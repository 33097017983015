<template>
    <div>
        <CoreContainer>
            <BaseContainerContent>
                <h1 class="mb-8 font-bold">
                    VUT theme
                </h1>


                <CoreUiForm v-slot="{ formData, globalFormError, isFormSubmitting }" :form="loginForm">

                    <CoreUiFormRow>
                        <!--  EMAIL INPUT  -->
                        <BaseUiFormInput
                            v-model:form="formData.email"
                            type="email"
                            autocomplete="username"
                            :label="$t('labels.email')"
                            :placeholder="$t('labels.your_email_address')"
                        />
                    </CoreUiFormRow>

                    <CoreUiFormRow>
                        <!--  PASSWORD INPUT  -->
                        <BaseUiFormInput
                            v-model:form="formData.password"
                            type="password"
                            autocomplete="current-password"
                            :label="$t('labels.password')"
                            :placeholder="$t('labels.your_password')"
                        />
                    </CoreUiFormRow>

                    <CoreUiFormRow indented no-margin-bottom>
                        <BaseUiButton
                            class="grow"
                            color="primary"
                            type="submit"
                            :aria-describedby="globalFormError.id"
                            :loading="isFormSubmitting"
                        >
                            {{ $t('labels.log_in') }}
                        </BaseUiButton>
                    </CoreUiFormRow>

                    <BaseUiFormError v-if="globalFormError.message" class="pt-4" :error-id="globalFormError.id">
                        {{ globalFormError.message }}
                    </BaseUiFormError>

                </CoreUiForm>

                <BaseUiButton :on-click="loginVut" class="my-5 w-full py-4">
                    Log in via VUT
                </BaseUiButton>

                <BaseUiButton :on-click="logOut" class="my-5 w-full py-4">
                    {{ $t('labels.log_out') }}
                </BaseUiButton>


                <pre>
{{ customer }}
                </pre>

            </BaseContainerContent>
        </CoreContainer>
    </div>
</template>

<script lang="ts" setup>

import { z } from 'zod'

const { logIn, loginThirdParty, logOut, customer } = useAuth()

const loginForm = useForm({
    schema: () => z.object({
        email: zStringMailType,
        password: zStringLongLengthType,
    }),
    onSubmit: async (data) => {
        await logIn({
            email: data.email,
            password: data.password,
        })
    },
})

async function loginVut() {
    await loginThirdParty(SocialiteTypeEnum.VUT)
}

</script>

<style lang="scss" scoped>

</style>
